import React, { Component } from "react";
import { Container } from "react-bootstrap";
import RIGHT_ARROW from "../../../images/right_arrow.svg";
import cogoToast from "cogo-toast";
import axios from "axios";
import ReCAPTCHA from "react-google-recaptcha";

class PartnerInputForm extends Component {
  render() {
    const { label, placeholder, name, onChange, value } = this.props;
    return (
      <div className="contact_us_form_input">
        <label htmlFor={name} className="partner_input_header">
          {label}
        </label>
        <input
          placeholder={placeholder}
          className="partner_input"
          name={name}
          onChange={onChange}
          value={value}
        />
      </div>
    );
  }
}

class ConnectWithUsCta extends Component {
  render() {
    const { label, onClick } = this.props;
    return (
      <div className="connect_with_us_cta_container">
        <button
          className="connect_with_us_cta"
          onClick={onClick}
        >
          <p>{label}</p>
          {/* <img src={RIGHT_ARROW} alt="right arrow" /> */}
        </button>
      </div>
    );
  }
}

class PartnerContactUs extends Component {
  constructor(props) {
    super(props);
    this.recaptchaRef = React.createRef();
    this.state = {
      form: {},
    };
  }

  handleContactWithUs = async () => {
    let _form = this.state.form;
    console.log(_form, "_form");

    const captchaToken = await this.recaptchaRef?.current?.executeAsync();
    console.log(captchaToken, "captchaToken");

    _form.recaptcha_token = captchaToken;
    this.recaptchaRef?.current?.reset();

    console.log(_form, "_form 2");

    try {
      const result = await axios.post("/api/partner-with-us", _form);

      console.log(result, "result");

      if (result.status == 200) {
        cogoToast.success("We will connect with you shortly");
        this.setState({ form: {} });
      }
    } catch (err) {
      console.log(err.response, "err");
      if (err?.response?.data?.message) {
        cogoToast.error(err?.response?.data?.message);
      }
    }
  };

  onChangeForm = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({
      form: {
        ...this.state.form,
        [name]: value,
      },
    });
  };

  render() {
    const { form } = this.state;
    return (
      <div id="contact_us">
        <Container
          fluid
          className="partner_contact_us_container d-flex flex-row justify-content-center"
        >
          <div className="partner_contact_us_content container-lg">
            <h1 className="partner_contact_header">
              Interested? Let’s have a coffee
            </h1>
            <form id="contact_us_form">
              <PartnerInputForm
                label={"Let’s start with your name"}
                placeholder={"Enter your full name..."}
                name={"name"}
                value={form["name"] ?? ""}
                onChange={this.onChangeForm}
              />
              <PartnerInputForm
                label={"Your company name"}
                placeholder={"Your company name..."}
                name={"company_name"}
                value={form["company_name"] ?? ""}
                onChange={this.onChangeForm}
              />
              <PartnerInputForm
                label={"Share your work email"}
                placeholder={"name@companyname.com..."}
                name={"email"}
                value={form["email"] ?? ""}
                onChange={this.onChangeForm}
              />
              <PartnerInputForm
                label={"Contact Number"}
                placeholder={"10 Digit Mobile Number..."}
                name={"contact_no"}
                value={form["contact_no"] ?? ""}
                onChange={this.onChangeForm}
              />
            </form>
            <ReCAPTCHA
              ref={this.recaptchaRef}
              sitekey={"6LfGaXknAAAAADKEdjejoqFVKcgrHIc4-RxT70wT"}
              size="invisible"
            />
            <ConnectWithUsCta
              label={"Connect with us"}
              onClick={this.handleContactWithUs}
            />
          </div>
        </Container>
      </div>
    );
  }
}

export default PartnerContactUs;
